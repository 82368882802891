import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  appearance: none;\n  overflow: hidden;\n  cursor: pointer;\n  position: relative;\n  display: inline-block;\n  height: ",
        ";\n  width: ",
        ";\n  vertical-align: middle;\n  transition: background-color 0.2s ease-in-out;\n  border: 0;\n  border-radius: 50%;\n  background-color: ",
        ";\n  box-shadow: ",
        ';\n\n  /* &:after {\n    border-radius: 50%;\n    content: "";\n    height: ',
        ";\n    left: 6px;\n    position: absolute;\n    top: 6px;\n    width: ",
        ";\n  } */\n\n  &:hover:not(:disabled):not(:checked) {\n    box-shadow: ",
        ";\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: ",
        ";\n  }\n\n  &:checked {\n    background-color: ",
        ";\n    /* &:after {\n      background-color: ",
        ";\n    } */\n  }\n\n  &:disabled {\n    cursor: default;\n    opacity: 0.6;\n  }\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space } from "styled-system";
import { scales } from "./types";
var getScale = function(param) {
    var scale = param.scale;
    switch(scale){
        case scales.SM:
            return "24px";
        case scales.MD:
        default:
            return "32px";
    }
};
var getCheckedScale = function(param) {
    var scale = param.scale;
    switch(scale){
        case scales.SM:
            return "12px";
        case scales.MD:
        default:
            return "20px";
    }
};
var Radio = styled.input.attrs({
    type: "radio"
}).withConfig({
    componentId: "sc-38069ad-0"
})(_templateObject(), getScale, getScale, function(param) {
    var theme = param.theme;
    return theme.colors.input;
}, function(param) {
    var theme = param.theme;
    return theme.shadows.inset;
}, getCheckedScale, getCheckedScale, function(param) {
    var theme = param.theme;
    return theme.shadows.focus;
}, function(param) {
    var theme = param.theme;
    return theme.shadows.focus;
}, function(param) {
    var theme = param.theme;
    return theme.colors.success;
}, function(param) {
    var theme = param.theme;
    return theme.radio.handleBackground;
}, space);
Radio.defaultProps = {
    scale: scales.MD,
    m: 0
};
export default Radio;
