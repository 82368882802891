import _define_property from "@swc/helpers/src/_define_property.mjs";
import { variants, scales } from "./types";
var _obj;
export var styleVariants = (_obj = {}, _define_property(_obj, variants.ROUND, {
    borderRadius: "32px"
}), _define_property(_obj, variants.FLAT, {
    borderRadius: 0
}), _obj);
var _obj1;
export var styleScales = (_obj1 = {}, _define_property(_obj1, scales.MD, {
    height: "16px"
}), _define_property(_obj1, scales.SM, {
    height: "8px"
}), _obj1);
export default styleVariants;
